<template>
  <div class="page-content">
    <page-breadcrumb title="Feedback" class="mb-1" />
    <data-table-ssr id="feedback_list" ref="feedback_list"
      :get-list-fn="getList" :columns="feedback_columns"
    />
  </div>
</template>

<script>
import service from "../service";

const feedback_columns = [
  { label: 'Feedback', field: 'content' },
  { label: 'Avatar', field: 'avatar', input_type: 'avatar', width: '100px', },
  { label: 'Name', field: 'name' },
  { label: 'Email', field: 'email' },
  { label: 'Created At', field: 'createdAt', input_type: 'datetime' },
];

export default {
  components: {},
  data() {
    return {
      feedback_columns,
    };
  },
  methods: {
    async getList({ limit, page, query }) {
      let response = await service.getList({
        query: encodeURIComponent(JSON.stringify(query)),
        page,
        limit,
      });
      let list = []; let total = 0;
      if (response.data.type === "DATA") {
        list = response.data.data.list;
        total = response.data.data.total;
      }
      return { list, total }
    },
  },
};
</script>
